@use "sass:math";

// Spacing
$margin: 12px;
$margin-half: math.div($margin, 2);
$margin-third: math.div($margin, 3);
$margin-fourth: math.div($margin, 4);

// Fonts
$small-font: 12px;
$medium-font: 14px;
$big-font: 18px;
$giant-font: 24px;
$title-font-1: 38px;
$title-font-2: 30px;
$title-font-3: 24px;
$title-font-4: 20px;

// Colors
$font-color: rgba(0, 0, 0, 0.85);
$button-green: #20bf6b;
$button-green-hover: #6fcf97;
$button-green-pressed: #219653;
$warning-orange: #faad14;
$invivo-blue: #0b435b;
$link-blue: #1890ff;
$light-blue: #e7f3ff;
$ant-primary-blue: #1677ff;
$pure-white: #fff;
$red: #f5222d;
$pale-red: #ff777c;
$volcano: #fa541c;
$grey-5: #d9d9d9;
$grey-7: #8c8c8c;

// Sign In
$signin-width: 286px;

// Inputs
$input-width: 300px;

// Navigation
$navigationWidth: 200px;
$navigationWidthCollapsed: 60px;
$navigation-color: #050505;

// mixins
// Ellipsis long text
@mixin ellipsis-long-text($max-width) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}
