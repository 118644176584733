@import "~@valencediscovery/kernel.styles/dist/variables.scss";
@import "~@valencediscovery/kernel.styles/dist/breakpoints.scss";

.navigation-menu {
  display: flex;
  flex-direction: column;
  background-color: $navigation-color;
  height: 100vh;
  max-width: $navigationWidth;

  transition: visibility 0s, opacity 1s, linear;
}

.navigation-menu:not(.ant-menu-inline-collapsed) {
  min-width: $navigationWidth;
}

.ant-menu-inline-collapsed {
  @include medium-device {
    visibility: hidden;
    opacity: 0;
    margin-left: -80px;
  }

  .navigation-hide-collapsed {
    display: none;
  }
}

.navigation-copyright {
  cursor: inherit;

  .navigation-copyright-text {
    font-size: 12px;
  }
}
