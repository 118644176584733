// Taken explictly from https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints

// Extra small devices (portrait phones, less than 576px)
@mixin extra-small-device {
  @media (max-width: 575.98px) {
    @content;
  }
}

// Small devices (landscape phones, less than 768px)
@mixin small-device {
  @media (max-width: 767.98px) {
    @content;
  }
}

// Medium devices (tablets, less than 992px)
@mixin medium-device {
  @media (max-width: 991.98px) {
    @content;
  }
}

// Large devices (desktops, less than 1200px)
@mixin large-device {
  @media (max-width: 1199.98px) {
    @content;
  }
}

// Small devices (landscape phones, 576px and up)
@mixin small-device-min {
  @media (min-width: 576px) {
    @content;
  }
}

// Medium devices (tablets, 768px and up)
@mixin medium-device-min {
  @media (min-width: 768px) {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin large-device-min {
  @media (min-width: 992px) {
    @content;
  }
}

// Extra large devices (large desktops, 1200px and up)
@mixin extra-large-device-min {
  @media (min-width: 1200px) {
    @content;
  }
}
